import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
// @mui
import { Box, Button, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
//components
import {
  FormProvider,
  RHFCheckbox,
  RHFDatePicker,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from '../../../components/hook-form';
//
import {
  CreateMyPropertyDto,
  PropertyDocument,
  PropertyDocumentWalkingMinutesFromStationEnum,
  PropertyLoanEarlyRepaymentSettingsLoanEarlyRepaymentTypeEnum,
} from 'src/api-client';
import useLocales from 'src/hooks/useLocales';
import { defaultCreatePropertyDto } from 'src/utils/defaultCreatePropertyDto';
import { defaultPropertySchema } from 'src/utils/defaultPropertySchema';
import axiosOpenApiInstance, { axiosAdminApiInstance } from 'src/utils/axiosOpenApi';
import useAuth from 'src/hooks/useAuth';
import Iconify from 'src/components/Iconify';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useLocation, useNavigate } from 'react-router-dom';
import { HtmlTooltip } from 'src/components/HtmlTooltip';
import {
  getPropertyAdvancedSettingsInfo,
  getPropertyBasicSettingsInfo,
  getPropertyVolatilitySettingsInfo,
  IPropertySettingInfo,
} from 'src/utils/getPropertySettingsInfo';
import { BANK_NAMES, RISKY_BANK_NAMES } from 'src/utils/bankNames';
import { WALKING_MINUTES_FROM_STATION_OPTIONS } from 'src/utils/walkingMinutesFromStationOptions';
import { getPropertyPriceDropRateFromBuiltAt } from 'src/utils/getPropertyPriceDropRateFromBuiltAt';
import { FLOOR_POSITION_OPTIONS } from 'src/utils/floorPositionOptions';

// ----------------------------------------------------------------------

// const LabelStyle = styled(Typography)(({ theme }) => ({
//   ...theme.typography.subtitle2,
//   color: theme.palette.text.secondary,
//   marginBottom: theme.spacing(1),
// }));

// ----------------------------------------------------------------------

type Props = {
  property?: PropertyDocument;
  pathOverride?: string;
};

export default function PropertyNewForm({ property, pathOverride }: Props) {
  const { translate } = useLocales();
  const context = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isAdmin = pathname.includes('admin');
  const isEdit = !!property;

  const methods = useForm<CreateMyPropertyDto>({
    resolver: yupResolver(defaultPropertySchema),
    defaultValues: isEdit
      ? property
      : { ...defaultCreatePropertyDto, owner: { id: context?.user?.id || '' } },
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    control,
    getValues,
  } = methods;

  const isRentRevenueGuaranteed = useWatch({
    name: 'basicSettings.isRentRevenueGuaranteed',
    control,
  });

  const {
    fields: fieldsBasicSettingsOtherItems,
    append: appendBasicSettingsOtherItems,
    remove: removeBasicSettingsOtherItems,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'basicSettings.otherItems', // unique name for your Field Array
  });
  const [basicSettingsOtherItemsCount, setBasicSettingsOtherItemsCount] = useState(
    property?.basicSettings.otherItems?.length || 0
  );

  const {
    fields: fieldsLoanEarlyRepayments,
    append: appendLoanEarlyRepayments,
    remove: removeLoanEarlyRepayments,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'loanEarlyRepaymentSettings.loanEarlyRepayments', // unique name for your Field Array
  });
  const [loanEarlyRepaymentsCount, setLoanEarlyRepaymentsCount] = useState(
    property?.loanEarlyRepaymentSettings?.loanEarlyRepayments?.length || 0
  );
  const [xAxisLabels, setXAxisLabels] = useState<string[]>([]);

  const onSubmit = async (dto: CreateMyPropertyDto) => {
    try {
      let newProperty;
      if (isEdit) {
        if (isAdmin) {
          const { data } = await axiosAdminApiInstance.adminPropertiesControllerUpdate({
            propertyId: property?.id || '',
            updateMyPropertyDto: dto
          });
          newProperty = data;
        } else {
          const { data } = await axiosOpenApiInstance.myPropertiesControllerUpdate({
            id: property?.id || '',
            updateMyPropertyDto: dto
          });
          newProperty = data;
        }
      } else {
        const { data } = await axiosOpenApiInstance.myPropertiesControllerCreate({ createMyPropertyDto: dto });
        newProperty = data;
      }
      reset();
      enqueueSnackbar(
        translate('toast.success', { value: translate(isEdit ? 'buttons.edit' : 'buttons.create') })
      );
      navigate(pathOverride ? `${pathOverride}/${newProperty.id}` : `/dashboard/my-properties/${newProperty.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const onInputAddressInfo = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const zipCode = e.target.value;
    if (zipCode.length !== 7) return;

    try {
      const addressInfo = await axiosOpenApiInstance.addressInfoControllerGetAddressInfo({ zipCode });
      setValue('address.principal', addressInfo.data.address1);
      setValue('address.city', addressInfo.data.address2);
    } catch (error) {
      console.error(error);
    }
  };

  const onInputBuiltAt = (builtAt: any) => {
    if (isEdit) return;
    setValue('volatilitySettings.propertyPriceDropRate', getPropertyPriceDropRateFromBuiltAt(builtAt))      
  };

  const isMountedRef = useIsMountedRef();

  const getXAxisLabels = useCallback(async () => {
    try {
      const [operationStartedAt, borrowingPeriodInYears] = getValues([
        'basicSettings.operationStartedAt',
        'basicSettings.borrowingPeriodInYears',
      ]);
      const xAxisLabelsRes = await axiosOpenApiInstance.xaxisLabelsControllerGetXAxisLabels({
        startDate: new Date(operationStartedAt).toISOString(),
        numberOfYears: borrowingPeriodInYears,
        cycle: 'by-year'
      });

      if (isMountedRef.current) {
        setXAxisLabels([''].concat(xAxisLabelsRes.data));
      }
    } catch (error) {
      console.error(error);
    }
  }, [isMountedRef, getValues]);

  useEffect(() => {
    getXAxisLabels();
  }, [getXAxisLabels]);

  function InfoTooltip({ info }: { info?: IPropertySettingInfo }) {
    return (
      <HtmlTooltip
        title={
          <Box
            dangerouslySetInnerHTML={{
              __html: `${info?.description || ''}<br><br><a target='_blank' href='${
                info?.referenceUrl
              }'>${info?.referenceUrl}</a>`,
            }}
          />
        }
      >
        <IconButton edge="end" size="small">
          <Iconify icon="eva:info-outline" />
        </IconButton>
      </HtmlTooltip>
    );
  }

  function BasicInfo() {
    return (
      <Stack sx={{ pt: 3 }} spacing={3}>
        <Typography variant="h6">{translate('property.basicInfo')}</Typography>
        <RHFTextField
          name="title"
          label={translate('property.title') + `(${translate('texts.private')})`}
        />
        <RHFTextField
          name="description"
          label={translate('property.description') + `(${translate('texts.private')})`}
          multiline
          rows={3}
        />
        <RHFDatePicker
          name="builtAt"
          views={['year', 'month']}
          label={translate('property.builtAt') + `(${translate('texts.privateBuiltAtMonth')})`}
          handleOnChange={onInputBuiltAt}
        />
        <RHFTextField
          name="numberOfSquareMeters"
          type="number"
          label={translate('property.numberOfSquareMeters') + `(${translate('texts.privateDecimals')})`}
          InputProps={{
            inputProps: { min: 0, step: 0.001 },
            endAdornment: (
              <InputAdornment position="end">{translate('units.squareMeters')}</InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="numberOfRooms"
          type="number"
          label={translate('property.numberOfRooms') + `(${translate('texts.private')})`}
          InputProps={{
            inputProps: { min: 1 },
            endAdornment: (
              <InputAdornment position="start">{translate('units.numberOfRooms')}</InputAdornment>
            ),
          }}
        />
        <RHFSelect
          name="floorPosition"
          label={translate('property.floorPosition')}
        >
           {FLOOR_POSITION_OPTIONS.map(({ label, value }) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
        </RHFSelect>
        <RHFSelect
          name="walkingMinutesFromStation"
          label={translate('property.walkingMinutesFromStation')}
        >
           {Object.values(PropertyDocumentWalkingMinutesFromStationEnum).map((label) => (
              <option key={label} value={label}>
                {WALKING_MINUTES_FROM_STATION_OPTIONS[label]}
              </option>
            ))}
        </RHFSelect>
      </Stack>
    );
  }

  function PropertyAddress() {
    return (
      <Stack sx={{ pt: 3, m: 0 }} spacing={3}>
        <Typography variant="h6">{translate('property.address.')}</Typography>
        <RHFTextField
          name="address.postalCode"
          label={translate('property.address.postalCode') + `(${translate('texts.private')})`}
          handleOnChange={onInputAddressInfo}
          placeholder="1070052"
        />
        <RHFTextField
          name="address.principal"
          label={translate('property.address.principal')}
        />
        <RHFTextField name="address.city" label={translate('property.address.city')} />
        <RHFTextField
          name="address.additionalInfo"
          label={translate('property.address.additionalInfo') + `(${translate('texts.private')})`}
        />
        <RHFTextField
          name="address.roomNumber"
          label={translate('property.address.roomNumber') + `(${translate('texts.private')})`}
          placeholder={translate("placeholders.roomNumber")}
        />
      </Stack>
    );
  }

  function OtherItems() {
    // useFieldArray -> https://tech.crassone.jp/posts/mui5-react-hook-form7 */
    return (
      <>
        {fieldsBasicSettingsOtherItems.map((field, index) => (
          <Box key={field.id} display="flex">
            <RHFTextField
              sx={{ mr: 2, flex: 3 }}
              name={`basicSettings.otherItems.${index}.name`}
              label={translate('property.basicSettings.otherItem.name')}
            />
            <RHFTextField
              sx={{ mr: 2, flex: 1 }}
              type="number"
              name={`basicSettings.otherItems.${index}.value`}
              label={translate('property.basicSettings.otherItem.value')}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
                ),
              }}
            />
            {/* remove 関数は特定の位置の input を削除、位置を指定しない場合は全てを削除 */}
            <Button
              aria-label="delete"
              onClick={() => {
                setBasicSettingsOtherItemsCount(
                  basicSettingsOtherItemsCount > 0 ? basicSettingsOtherItemsCount - 1 : 0
                );
                removeBasicSettingsOtherItems(index);
              }}
            >
              {translate('buttons.delete')}
            </Button>
          </Box>
        ))}
        <Button
          sx={{ mt: 1 }}
          startIcon={<Iconify icon="eva:plus-outline" />}
          onClick={() => {
            setBasicSettingsOtherItemsCount(basicSettingsOtherItemsCount + 1);
            appendBasicSettingsOtherItems({
              name: '',
              value: 0,
            });
          }}
          disabled={basicSettingsOtherItemsCount >= 3}
        >
          {translate('buttons.addItem')}
        </Button>
      </>
    );
  }

  function BasicSettings() {
    const name = (value: string) => `basicSettings.${value}`;
    const label = (value: string) => translate(`property.basicSettings.${value}`);

    return (
      <Stack sx={{ pt: 3 }} spacing={3}>
        <Typography variant="h6">{translate('property.basicSettings.')}</Typography>
        <RHFDatePicker
          name={name('operationStartedAt')}
          views={['year', 'month']}
          label={label('operationStartedAt')}
        />
        <RHFTextField
          name={name('operationYears')}
          type="number"
          label={label('operationYears')}
          InputProps={{
            inputProps: { min: 1 },
            endAdornment: (
              <InputAdornment position="end">{translate('units.years')}</InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name={name('propertyPrice')}
          type="number"
          label={label('propertyPrice')}
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: <InputAdornment position="end">{translate('units.yen')}</InputAdornment>,
          }}
        />
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('initialPayment')}
            type="number"
            label={label('initialPayment')}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
              ),
            }}
          />
          <InfoTooltip info={getPropertyBasicSettingsInfo('initialPayment')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFCheckbox
            name={name('isRentRevenueGuaranteed')}
            label={String(translate('texts.isRentRevenueGuaranteed'))}
            labelPlacement="start"
            sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
          />
          <InfoTooltip info={getPropertyBasicSettingsInfo('isRentRevenueGuaranteed')} />
        </Box>
        <RHFTextField
          name={name('rentRevenue')}
          type="number"
          label={isRentRevenueGuaranteed ? label('rentRevenueGuaranteed') : label('rentRevenue')}
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: <InputAdornment position="end">{translate('units.yen')}</InputAdornment>,
          }}
        />
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('rentCollectionServiceFee')}
            type="number"
            label={label('rentCollectionServiceFee')}
            disabled={isRentRevenueGuaranteed}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
              ),
            }}
          />
          <InfoTooltip info={getPropertyBasicSettingsInfo('rentCollectionServiceFee')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('managementCost')}
            type="number"
            label={label('managementCost')}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
              ),
            }}
          />
          <InfoTooltip info={getPropertyBasicSettingsInfo('managementCost')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('repairReserve')}
            type="number"
            label={label('repairReserve')}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
              ),
            }}
          />
          <InfoTooltip info={getPropertyBasicSettingsInfo('repairReserve')} />
        </Box>

        <OtherItems />

        <RHFSelect
            name={name('bankName')}
            label={label('bankName')}
          >
            {[""].concat(BANK_NAMES).concat(RISKY_BANK_NAMES).map((label) => (
              <option key={label} value={label}>
                {label}
              </option>
            ))}
          </RHFSelect>
        <RHFTextField
          name={name('borrowingAmount')}
          type="number"
          label={label('borrowingAmount')}
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: <InputAdornment position="end">{translate('units.yen')}</InputAdornment>,
          }}
        />
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('annualInterestRate')}
            type="number"
            label={label('annualInterestRate')}
            InputProps={{
              inputProps: { min: 0, step: 0.001 },
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <InfoTooltip info={getPropertyBasicSettingsInfo('annualInterestRate')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('borrowingPeriodInYears')}
            type="number"
            label={label('borrowingPeriodInYears')}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">{translate('units.years')}</InputAdornment>
              ),
            }}
          />
          <InfoTooltip info={getPropertyBasicSettingsInfo('borrowingPeriodInYears')} />
        </Box>
      </Stack>
    );
  }

  function LoanEarlyRepayments() {
    return (
      <Stack sx={{ pt: 3 }} spacing={3}>
        <Typography variant="h6">
          {translate('property.loanEarlyRepaymentSettings.loanEarlyRepayments.')}
        </Typography>
        <RHFRadioGroup
          name="loanEarlyRepaymentSettings.loanEarlyRepaymentType"
          options={[
            PropertyLoanEarlyRepaymentSettingsLoanEarlyRepaymentTypeEnum.AmountReduction,
            PropertyLoanEarlyRepaymentSettingsLoanEarlyRepaymentTypeEnum.PeriodShortening,
          ]}
          getOptionLabel={['減額方式', '期間短縮方式']}
        />
        <>
          {fieldsLoanEarlyRepayments.map((field, index) => (
            <Box key={field.id} display="flex">
              <RHFSelect
                sx={{ mr: 2, flex: 2 }}
                name={`loanEarlyRepaymentSettings.loanEarlyRepayments.${index}.date`}
                label={translate('property.loanEarlyRepaymentSettings.loanEarlyRepayments.date')}
              >
                {xAxisLabels.map((label) => (
                  <option key={label} value={label}>
                    {label.substring(0, label.indexOf('年'))}
                  </option>
                ))}
              </RHFSelect>
              <RHFTextField
                sx={{ mr: 2, flex: 2 }}
                type="number"
                name={`loanEarlyRepaymentSettings.loanEarlyRepayments.${index}.amount`}
                label={translate('property.loanEarlyRepaymentSettings.loanEarlyRepayments.amount')}
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: (
                    <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
                  ),
                }}
              />
              {/* remove 関数は特定の位置の input を削除、位置を指定しない場合は全てを削除 */}
              <Button
                aria-label="delete"
                onClick={() => {
                  setLoanEarlyRepaymentsCount(
                    loanEarlyRepaymentsCount > 0 ? loanEarlyRepaymentsCount - 1 : 0
                  );
                  removeLoanEarlyRepayments(index);
                }}
              >
                {translate('buttons.delete')}
              </Button>
            </Box>
          ))}
          <Button
            sx={{ mt: 1 }}
            startIcon={<Iconify icon="eva:plus-outline" />}
            onClick={() => {
              getXAxisLabels();
              setLoanEarlyRepaymentsCount(loanEarlyRepaymentsCount + 1);
              appendLoanEarlyRepayments({
                date: '',
                amount: 0,
              });
            }}
            disabled={loanEarlyRepaymentsCount >= 50}
          >
            {translate('buttons.addItem')}
          </Button>
        </>
      </Stack>
    );
  }

  function AdvancedSettings() {
    const name = (key: string) => `advancedSettings.${key}.value`;
    const label = (key: string) => translate(`property.advancedSettings.${key}`);

    return (
      <Stack sx={{ pt: 3 }} spacing={3}>
        <Typography variant="h6">{translate('property.advancedSettings.')}</Typography>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('propertyAcquisitionTax')}
            type="number"
            label={label('propertyAcquisitionTax')}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
              ),
            }}
          />
          <InfoTooltip info={getPropertyAdvancedSettingsInfo('propertyAcquisitionTax')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('otherPayments')}
            type="number"
            label={label('otherPayments')}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
              ),
            }}
          />
          <InfoTooltip info={getPropertyAdvancedSettingsInfo('otherPayments')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('propertyTax')}
            type="number"
            label={label('propertyTax')}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
              ),
            }}
          />
          <InfoTooltip info={getPropertyAdvancedSettingsInfo('propertyTax')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('fireInsurance')}
            type="number"
            label={label('fireInsurance')}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
              ),
            }}
          />
          <InfoTooltip info={getPropertyAdvancedSettingsInfo('fireInsurance')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('bathroomDryer')}
            type="number"
            label={label('bathroomDryer')}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
              ),
            }}
          />
          <InfoTooltip info={getPropertyAdvancedSettingsInfo('bathroomDryer')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('airConditioner')}
            type="number"
            label={label('airConditioner')}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
              ),
            }}
          />
          <InfoTooltip info={getPropertyAdvancedSettingsInfo('airConditioner')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('boiler')}
            type="number"
            label={label('boiler')}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
              ),
            }}
          />
          <InfoTooltip info={getPropertyAdvancedSettingsInfo('boiler')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('wallpaperReplacement')}
            type="number"
            label={label('wallpaperReplacement')}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
              ),
            }}
          />
          <InfoTooltip info={getPropertyAdvancedSettingsInfo('wallpaperReplacement')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('floorReplacement')}
            type="number"
            label={label('floorReplacement')}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">{translate('units.yen')}</InputAdornment>
              ),
            }}
          />
          <InfoTooltip info={getPropertyAdvancedSettingsInfo('floorReplacement')} />
        </Box>
      </Stack>
    );
  }

  function VolatilitySettings() {
    const name = (value: string) => `volatilitySettings.${value}`;
    const label = (value: string) => translate(`property.volatilitySettings.${value}`);

    return (
      <Stack sx={{ pt: 3 }} spacing={3}>
        <Typography variant="h6">{translate('property.volatilitySettings.')}</Typography>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('rentChangeRate')}
            type="number"
            label={label('rentChangeRate')}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <InfoTooltip info={getPropertyVolatilitySettingsInfo('rentChangeRate')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('sellingPriceChangeRate')}
            type="number"
            label={label('sellingPriceChangeRate')}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <InfoTooltip info={getPropertyVolatilitySettingsInfo('sellingPriceChangeRate')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('propertyPriceDropRate')}
            type="number"
            label={label('propertyPriceDropRate')}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <InfoTooltip info={getPropertyVolatilitySettingsInfo('propertyPriceDropRate')} />
        </Box>
        <Box display="flex" alignItems="center">
          <RHFTextField
            name={name('vacancyRate')}
            type="number"
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label={label('vacancyRate')}
          />
          <InfoTooltip info={getPropertyVolatilitySettingsInfo('vacancyRate')} />
        </Box>
      </Stack>
    );
  }

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={8}> */}
        <Stack spacing={3}>
          {/* <div>
                  <LabelStyle>{translate('property.cover')}</LabelStyle>
                  <RHFUploadSingleFile
                    name="cover"
                    accept="image/*"
                    maxSize={3145728}
                    onDrop={handleDrop}
                  />
                </div> */}

          <BasicInfo />

          <PropertyAddress />

          <BasicSettings />

          <LoanEarlyRepayments />

          <AdvancedSettings />

          <VolatilitySettings />

          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            size="large"
            loading={isSubmitting}
          >
            {translate(isEdit ? 'buttons.edit' : 'buttons.create')}
          </LoadingButton>
        </Stack>
        {/* </Grid>
        </Grid> */}
      </FormProvider>
    </>
  );
}
