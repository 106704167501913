import { TooltipProps, tooltipClasses, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0} leaveTouchDelay={60000} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 1)',
    maxWidth: 450,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}));
