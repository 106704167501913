import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import { PATH_AFTER_LOGIN } from '../config';
import AdminGuard from 'src/guards/AdminGuard';
import { SearchProvider } from 'src/contexts/SearchContext';
import VerifiedGuard, { NotVerifiedGuard } from 'src/guards/VerifiedGuard';
import useResponsive from 'src/hooks/useResponsive';
import PropertiesPost from 'src/pages/dashboard/PropertiesPost';
import UserNameGuard from 'src/guards/UserNameGuard';
// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const isDesktop = useResponsive('up', 'lg');

  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'line',
          element: (
            <GuestGuard>
              <LineLogin />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <AuthGuard><NotVerifiedGuard><VerifyCode /></NotVerifiedGuard></AuthGuard> },
      ],
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <UserNameGuard>
            <DashboardLayout />
          </UserNameGuard>
        </AuthGuard>
      ),
      children: [
        // Dashboard
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: '/dashboard',
          children: [
            { element: <Navigate to="/dashboard/properties" replace />, index: true },
            { path: 'properties', element: isDesktop ? <Properties /> : <PropertiesPost /> },
            { path: 'properties/:propertyId', element: <Property /> },
            { path: 'properties/:propertyId/simulate', element: <PropertySimulate /> },
            { path: 'properties/:propertyId/loan-payments', element: <PropertyLoanPaymentsSheet /> },
            { path: 'my-properties', element: <MyProperties /> },
            { path: 'my-properties/:propertyId', element: <MyProperty /> },
            { path: 'my-properties/new', element: <VerifiedGuard><MyPropertyNew /></VerifiedGuard> },
            { path: 'my-properties/:propertyId/edit', element: <VerifiedGuard><MyPropertyEdit /></VerifiedGuard> },
            { path: 'my-properties/:propertyId/simulate', element: <PropertySimulate /> },
            { path: 'my-properties/:propertyId/loan-payments', element: <PropertyLoanPaymentsSheet /> },
            { path: 'disclaimer', element: <Disclaimer /> },
            { path: 'profile', element: <Profile /> },
            { path: 'profile/line', element: <LineAccountLink /> },
          ],
        },
      ],
    },
    {
      path: 'admin',
      element: (
        <AdminGuard>
          <UserNameGuard>
            <VerifiedGuard>
              <SearchProvider>
                <DashboardLayout />
              </SearchProvider>
            </VerifiedGuard>
          </UserNameGuard>
        </AdminGuard>
      ),
      children: [
        {
          path: '/admin',
          children: [
            { element: <Navigate to="/admin/users" replace />, index: true },
            { path: 'properties', element: <UserProperties /> },
            { path: 'properties/:propertyId', element: <UserProperty /> },
            { path: 'properties/:propertyId/edit', element: <UserPropertyEdit /> },
            { path: 'properties/:propertyId/simulate', element: <PropertySimulate /> },
            { path: 'properties/:propertyId/loan-payments', element: <PropertyLoanPaymentsSheet /> },
            { path: 'users', element: <Users /> },
            { path: 'users/:userId', element: <User /> },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
// IMPORT COMPONENTS
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const LineLogin = Loadable(lazy(() => import('../pages/auth/LineLogin')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Properties = Loadable(lazy(() => import('../pages/dashboard/Properties')));
// const PropertiesPost = Loadable(lazy(() => import('../pages/dashboard/PropertiesPost')));
const Property = Loadable(lazy(() => import('../pages/dashboard/Property')));
const MyProperties = Loadable(lazy(() => import('../pages/dashboard/MyProperties')));
const MyProperty = Loadable(lazy(() => import('../pages/dashboard/MyProperty')));
const MyPropertyNew = Loadable(lazy(() => import('../pages/dashboard/MyPropertyNew')));
const MyPropertyEdit = Loadable(lazy(() => import('../pages/dashboard/MyPropertyEdit')));
const PropertySimulate = Loadable(
  lazy(() => import('../pages/dashboard/simulate/PropertySimulate'))
);
const PropertyLoanPaymentsSheet = Loadable(
  lazy(() => import('../pages/dashboard/loan-payments-sheet/PropertyLoanPaymentsSheet'))
);
const Disclaimer = Loadable(lazy(() => import('../pages/dashboard/Disclaimer')));
const Profile = Loadable(lazy(() => import('../pages/dashboard/user/Profile')));
const LineAccountLink = Loadable(lazy(() => import('../pages/dashboard/user/LineAccountLink')));
// Admin
const Users = Loadable(lazy(() => import('../pages/dashboard/admin/Users')));
const User = Loadable(lazy(() => import('../pages/dashboard/admin/User')));
const UserProperty = Loadable(lazy(() => import('../pages/dashboard/admin/Property')));
const UserPropertyEdit = Loadable(lazy(() => import('../pages/dashboard/admin/PropertyEdit')));
const UserProperties = Loadable(lazy(() => import('../pages/dashboard/admin/Properties')));