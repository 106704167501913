import {
  CreateMyPropertyDto,
  PropertyAddressDto,
  PropertyAdvancedSettingsDto,
  PropertyBasicSettingsDto,
  PropertyDocumentWalkingMinutesFromStationEnum,
  PropertyLoanEarlyRepaymentSettings,
  PropertyLoanEarlyRepaymentSettingsLoanEarlyRepaymentTypeEnum,
  PropertyOwnerDto,
  PropertyVolatilitySettingsDto,
} from 'src/api-client';

const defaultOwner: PropertyOwnerDto = { id: '', name: '', avatarUrl: '' };
const defaultAddress: PropertyAddressDto = {
  postalCode: '',
  principal: '',
  city: '',
  additionalInfo: '',
  roomNumber: '',
};
const defaultBasicSettings: PropertyBasicSettingsDto = {
  operationStartedAt: new Date().toISOString(),
  operationYears: 50,
  propertyPrice: 30000000,
  initialPayment: 100000,
  rentRevenue: 90000,
  isRentRevenueGuaranteed: false,
  rentCollectionServiceFee: 4500,
  managementCost: 6000,
  repairReserve: 1500,
  bankName: '',
  borrowingAmount: 29900000,
  annualInterestRate: 1.9, // (%)
  borrowingPeriodInYears: 35,
  otherItems: [],
};
const defaultAdvancedSettings: PropertyAdvancedSettingsDto = {
  // 初年度のみ
  propertyAcquisitionTax: { value: 150000, yearInterval: 0, isCyclical: false },
  otherPayments: { value: 0, yearInterval: 0, isCyclical: false },
  // 毎年
  propertyTax: { value: 50000, yearInterval: 1, isCyclical: true },
  // 10年ごと
  fireInsurance: { value: 15000, yearInterval: 10, isCyclical: true },
  bathroomDryer: { value: 100000, yearInterval: 10, isCyclical: true },
  airConditioner: { value: 80000, yearInterval: 10, isCyclical: true },
  boiler: { value: 180000, yearInterval: 10, isCyclical: true },
  wallpaperReplacement: { value: 100000, yearInterval: 10, isCyclical: true },
  floorReplacement: { value: 100000, yearInterval: 30, isCyclical: true },
};
const defaultVolatilitySettings: PropertyVolatilitySettingsDto = {
  // 変動率
  rentChangeRate: -0.5, // 年率（％）
  vacancyRate: 0,
  sellingPriceChangeRate: -0.5, // 年率（％）
  propertyPriceDropRate: -20, // 初年度（％）
};

const defaultLoanEarlyRepaymentSettings: PropertyLoanEarlyRepaymentSettings = {
  loanEarlyRepaymentType:
    PropertyLoanEarlyRepaymentSettingsLoanEarlyRepaymentTypeEnum.AmountReduction,
  loanEarlyRepayments: [],
};

export const defaultCreatePropertyDto: CreateMyPropertyDto = {
  title: '',
  builtAt: new Date().toISOString(),
  owner: defaultOwner,
  numberOfSquareMeters: 25,
  numberOfRooms: 1,
  floorPosition: 1,
  walkingMinutesFromStation: PropertyDocumentWalkingMinutesFromStationEnum.OneToFourMinutes,
  address: defaultAddress,
  description: '',
  cover: '',
  basicSettings: defaultBasicSettings,
  advancedSettings: defaultAdvancedSettings,
  volatilitySettings: defaultVolatilitySettings,
  loanEarlyRepaymentSettings: defaultLoanEarlyRepaymentSettings,
};
